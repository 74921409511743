exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-components-about-celebration-tsx": () => import("./../../../src/pages/about-components/about-celebration.tsx" /* webpackChunkName: "component---src-pages-about-components-about-celebration-tsx" */),
  "component---src-pages-about-components-about-description-tsx": () => import("./../../../src/pages/about-components/about-description.tsx" /* webpackChunkName: "component---src-pages-about-components-about-description-tsx" */),
  "component---src-pages-about-components-about-images-tsx": () => import("./../../../src/pages/about-components/about-images.tsx" /* webpackChunkName: "component---src-pages-about-components-about-images-tsx" */),
  "component---src-pages-about-components-about-partnership-tsx": () => import("./../../../src/pages/about-components/about-partnership.tsx" /* webpackChunkName: "component---src-pages-about-components-about-partnership-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-animated-logo-btn-animated-logo-btn-tsx": () => import("./../../../src/pages/animated-logo-btn/animated-logo-btn.tsx" /* webpackChunkName: "component---src-pages-animated-logo-btn-animated-logo-btn-tsx" */),
  "component---src-pages-animated-logo-btn-animated-logo-svg-tsx": () => import("./../../../src/pages/animated-logo-btn/animated-logo-svg.tsx" /* webpackChunkName: "component---src-pages-animated-logo-btn-animated-logo-svg-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-home-components-home-our-services-tsx": () => import("./../../../src/pages/home-components/home-our-services.tsx" /* webpackChunkName: "component---src-pages-home-components-home-our-services-tsx" */),
  "component---src-pages-home-components-home-recent-works-tsx": () => import("./../../../src/pages/home-components/home-recent-works.tsx" /* webpackChunkName: "component---src-pages-home-components-home-recent-works-tsx" */),
  "component---src-pages-home-components-home-secondary-title-tsx": () => import("./../../../src/pages/home-components/home-secondary-title.tsx" /* webpackChunkName: "component---src-pages-home-components-home-secondary-title-tsx" */),
  "component---src-pages-home-components-home-subtitle-tsx": () => import("./../../../src/pages/home-components/home-subtitle.tsx" /* webpackChunkName: "component---src-pages-home-components-home-subtitle-tsx" */),
  "component---src-pages-home-components-home-title-tsx": () => import("./../../../src/pages/home-components/home-title.tsx" /* webpackChunkName: "component---src-pages-home-components-home-title-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manifest-tsx": () => import("./../../../src/pages/manifest.tsx" /* webpackChunkName: "component---src-pages-manifest-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

